// Body
$body-bg: #FEFEFE;

// Typography
$font-family-sans-serif: 'Barlow', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$primary: #FCAE03;
$primarybis:#F79B2D;
$primary-color:#FCAE03;
$border-color-base: #FCAE03;

$icewhite:#fff;
$white:#fefefe;
$black:#333333;
$lightblack:#444444;
$lightgrey:#FAFAFA;
$grey:#E4E4E4;
$darkgrey:#C4C4C4;
$obviousgrey:#979797;



$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;

$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;