h1, h2, h3, h4, h5, h6, p, a {
    font-family: $font-family-sans-serif !important;
}

h3{
    font-weight: 800 !important;
}

h5{
    font-weight: 500;
}

h4.ant-typography,.ant-typography+h4.ant-typography{
    margin:0;
}

h6{
    margin-bottom:1em !important;
}

h5.empty{
    color:$darkgrey !important;
    font-size:1rem;
    font-weight: 500 !important;
}

/* link */

a{
    font-weight: 800;
    letter-spacing: 0.04em;
}

a:hover{
    color:$primarybis !important;
}

a.orange, i.orange, h6.orange{
    color:$primary;
}

a.white, i.white{
    color:$white;
}