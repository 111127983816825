// Fonts
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;700;800&display=swap');

// Bootstrap - AOS - Antd
@import '~bootstrap/scss/bootstrap';
@import "~aos/dist/aos.css";
@import "~antd/dist/antd.min.css";

//Style

// Variables
@import 'variables';


//Global
@import 'general';
@import 'typo';
@import 'ant';
@import 'navbar';


//Layout
@import 'layout/home';
@import 'layout/login';