// Variables
@import 'variables';

/* HOME PAGE */

/* General */

#homepage{
    background: $icewhite;
}

#homepage section{
    margin-left:auto;
    margin-right: auto;
}

#homepage section header{
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

#homepage section header h3{
    margin-right:5vw;
}

#homepage section article p{
    max-width: 33vw;
}

#homepage section{
    padding:10vh 5vw;
}

#homepage section.orange p{
    font-weight: 400;
}

#homepage button:not(.ant-pagination-item-link){
    color:$icewhite;
}

/* Top */

#toppage{
    height:95vh;
    background:#111 url("../img/truck.jpg");
    background-size: cover;
    position:relative;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index:50;

    box-shadow: 10px 10px 30px 0px rgba(10,10,10,0.75) inset;
    -webkit-box-shadow: 10px 10px 30px 0px rgba(10,10,10,0.75)inset;
    -moz-box-shadow: 10px 10px 30px 0px rgba(10,10,10,0.75)inset;
}

#toppage article{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100vw;
    height:100%;
    background: url("../img/road.jpg") no-repeat;
    background-size: 30vw;
    background-position:75% 25%;
}

#toppage img{
    max-width:30vw;
    margin-bottom:5vh;
    margin-right:17vw;
}

#toppage a{
    margin-right:17vw;
    color:$primary;
    margin-top:20px;
}

#toppage #scroll{
    height:60px;
    width: 80px;
    position: absolute;
    right:50px;
    bottom:50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: 2s infinite bounce;
}

#toppage #scroll i{
    color:$primary;
}

@keyframes bounce {
    0% {
      transform: translateY(-20px);
    }
  
    50% {
      transform: translateY(20px);
    }

    100%{
        transform: translateY(-20px);   
    }
  }

/* Trainings */

section#show_trainings{
    margin-top:-20vh;
    z-index: 100;
    position: relative;
    padding:0vh 5vw 10vh 5vw;
}

section#show_trainings .intro_trainings{
    background: url("../img/person.png") no-repeat;
    background-size: contain;
    background-position: left;
    min-height: 611px;
    margin:0 20%;

    display: flex;
    justify-content: center;
    align-items: center;
}

section#show_trainings .intro_trainings a{
    margin:60% 70px 0 0;
    transform: scale(1.5);
}

section#show_trainings .intro_trainings button{
    padding:20px;
}

div#home_trainings article{
    display: flex;
    flex-direction: row;
    margin-top:50px;
}

div#home_trainings article h3{
    width:100%;
    text-align: center;
}

div#home_trainings article .trainings_list{
    width:30%;
}

div#home_trainings article ul.ant-pagination{
    margin-right:50px;
}

div#home_trainings article li:not(.ant-pagination-item):not(.ant-pagination-prev):not(.ant-pagination-next){
    margin-right:50px;
    padding:20px;
    border-radius: 15px 15px 0 15px;
}

div#home_trainings article li.selected{
    background:$primary;
    
}

div#home_trainings article li.selected h5, div#home_trainings article li.selected h6.orange{
    color:$icewhite;
}

div#home_trainings article .training_details{
    width:50%;
}

div#home_trainings article .training_info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0 20px;
    width:70%;
}


div#home_trainings .next_trainings{
    margin-left:30px;
    min-width: 50%;
}

div#home_trainings .next_trainings ul{
    margin-top:20px;
    min-height: 25vh;
    max-height: 50vh;
    padding-right:80px;
    overflow-y: auto;
}

div#home_trainings .next_trainings ul::-webkit-scrollbar-track
{
  background-color: $grey;
}

div#home_trainings .next_trainings ul::-webkit-scrollbar
{
  width: 7px;
  background-color: $grey;
}

div#home_trainings .next_trainings ul::-webkit-scrollbar-thumb
{
  background-color: $black;
  border: 0px;
}

div#home_trainings .next_trainings .occurence_trainings{
    min-width: max-content;
    background:$lightgrey;
    margin:10px 0;

    display: flex;
    flex-direction: row;
}

div#home_trainings .next_trainings .occurence_trainings div{
    margin:0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

div#home_trainings .next_trainings .occurence_trainings div:nth-child(2){
    align-items: center;
    text-align: center;
}

div#home_trainings .next_trainings .occurence_trainings p, div#home_trainings .next_trainings .occurence_trainings h6{
    margin:0 !important;
}
/* Actuality */

div#actualities ul.ant-list-items{
    display: flex;
    padding-bottom:200px;
}

div#actualities ul.ant-list-items li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding:50px;
    max-width: 33%;  
    position: relative;
    min-width: auto;
    box-shadow: none;
    border:none;
}

div#actualities ul.ant-list-items li article{
    display: flex;
    flex-direction: column;
}

div#actualities ul.ant-list-items li span{
    margin-bottom: 30px;
}

div#actualities ul.ant-list-items li a{
    margin-top: 15px;
    text-align: right;
}

div#actualities li{
    background:transparent;
}

div#actualities li:first-child, #noactualities li{
    background: $primary;
    color:$white;
    border-radius: 15px 15px 0 15px;
}

#noactualities li{
    padding:50px;
}

div#actualities ul.ant-list-items li:first-child h5, div#actualities ul.ant-list-items li:first-child a, div#actualities ul.ant-list-items li:first-child a:hover, #noactualities h5,#noactualities a{
    color:$white !important;
}

div#actualities ul.ant-list-items li:first-child::after{
    content:"";
    position: absolute;
    bottom:-200px;
    right:-200px;
    background: url("../img/news.jpg");
    width:200px;
    height:200px;
    border-radius: 0 15px 15px 15px;
}

/* coache */

#adrcoaches{
    display: flex;
    justify-content: center;
    align-items: center;
}

#adrcoaches article{
    text-align: right;
}

#adrcoaches article p{
    max-width: 20vw;
}

#adrcoaches ul.adr{
    display: flex;
    flex-direction: row;
}

#adrcoaches ul.adr li img{
    max-width: 80%;
}

/* history formatrans*/

#FormatransHistory{
    display: flex;
    justify-content: center;
    align-items: center;
}

#FormatransHistory article ul li{
    display: flex;
}

#FormatransHistory article ul li i, #FormatransHistory article ul li .svg-inline--fa{
    margin-right:20px;
    color:$primary;
}


#FormatransHistory img{
    width: 100%;
    padding:0 5vw;
}

/* history sprl */

#fourieHistory{
    display: flex;
    justify-content: center;
    background: $lightgrey;
    background: linear-gradient(180deg, $icewhite 18%, $lightgrey 18%);
}

#fourieHistory article{
    max-width: 20vw;
    margin-top:10vh;
}

#fourieHistory article:nth-child(2){
    margin-right:3vw;
}

#fourieHistory article:last-child{
    padding-top:calc(1.2em + 1.75rem);
}

#fourieHistory img{
    width: 100%;
    margin-top:-20vh;
    border-radius: 15px 15px 0 15px;
    padding-right:5vw;
}

/* footer */
#homepage footer{
    padding:5vh 0;
    background: $black;
    display: flex;
    justify-content: center;
    align-items: center;

}

/* RESPONSIVITE */

@media screen and (min-width:1920px){
    #homepage section article p{
        max-width: 20vw;
    }
}

@media screen and (max-width:1023px){
    #homepage section article{
        max-width: 80vw;
    }

    #toppage{
        height: 75vh;
    }

    #toppage article{
        background:none;
    }

    section#show_trainings .intro_trainings{
        margin:0;
    }

    div#home_trainings article .training_info{
        flex-direction: column;
    }

    div#home_trainings article .training_details{
        width:100%;
        margin-bottom:30px;
    }

    div#home_trainings .next_trainings{
        margin:0;
    }

    div#actualities ul.ant-list-items, #FormatransHistory{
        flex-direction: column;
        padding-bottom: 0;
    }

    div#actualities ul.ant-list-items li:first-child::after{
        display: none;
    }

    section#fourieHistory{
        padding:10vh 10vw;
        justify-content: space-between;
    }

    section#fourieHistory article:first-child{
        display: none;
    }

    #homepage section#fourieHistory article{
        padding:0;
        max-width:45%;
    }

    #homepage section#fourieHistory article:last-child{
        padding-top:calc(1.2em + 1.75rem);
    }

}

@media screen and (max-width:768px){
    #toppage img{
        max-width: 50vw;
        margin:0;
    }

    #toppage button{
        margin:0;
    }

    #homepage section article p{
        max-width: 80vw;
    }

    #toppage a{
        margin-right:0;
    }

    div#home_trainings article li:not(.ant-pagination-item):not(.ant-pagination-prev):not(.ant-pagination-next){
        margin-right: 20px;
    }

    div#home_trainings article .trainings_list{
        width:auto;
        min-width: 30%;
    }

    section#show_trainings .intro_trainings{
        background-size: contain;
        min-height: 411px;
    }

    section#show_trainings .intro_trainings button{
        margin:100px 0 0 0;
        transform: scale(1.2);
    }

    div#home_trainings article li{
        margin-right:0;
    }

    div#home_trainings article li h5{
        font-size:1rem;
    }

    div#home_trainings article li h6{
        font-size:0.7rem;
    }

    div#home_trainings article .training_info{
        width:100%;
    }

    div#home_trainings .next_trainings .occurence_trainings{
        flex-direction: column;
    }

    div#home_trainings .next_trainings .occurence_trainings div:nth-child(2){
        align-items:flex-start;
        text-align: left;
    }

    div#actualities ul.ant-list-items li{
        padding:25px 20px;
        max-width:100%;
    }

    #adrcoaches{
        flex-direction: column;
    }

    #adrcoaches article{
        text-align: left;
    }

    #adrcoaches article ul{
        margin-top:50px;
    }

    section#fourieHistory{
        flex-direction: column;
        margin-top:10vh;
        background: #FAFAFA;
    }

    #homepage section#fourieHistory article:last-child{
        padding:0;
    }

    #homepage section#fourieHistory article{
        max-width: 100%;
        margin:0;
    }

    footer a{
        font-size:0.6rem;
    }

}
@media screen and (max-width:480px){
    section#show_trainings .intro_trainings{
        background-size: contain;
        min-height: 311px;
    }

    div#home_trainings article{
        flex-direction: column;
    }

    div#home_trainings article .trainings_list{
        width:100%;
    }

    div#home_trainings article li:not(.ant-pagination-item):not(.ant-pagination-prev):not(.ant-pagination-next){
        padding:10px;
        overflow-x: hidden;
    }
    
}