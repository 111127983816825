// Variables
@import 'variables';

/* réécriture du style ant */

/* LAYOUT */

.ant-layout,.ant-layout-header, .App{
    background: $body-bg;
  }
  
  .ant-row:not(.ant-form-item){
    display: flex;
    flex-direction: row;
  }
  
  .ant-row .ant-col-12{
    width:50%;
  }
  
  .ant-layout-header{
    background:transparent;
  }
  
  .app-header{
    margin-bottom:5vh;
  }
  
  /* MENU */
  .ant-dropdown-menu{
    padding:0;
  }
  
  /* DESCRIPTION */
  .ant-descriptions-header{
    margin-bottom:0;
  }
  
  .ant-descriptions-title{
    text-align: left;
  }
  
  /* GENERAL */
  
  /* COMPONENTS */
  
  .ant-avatar{
    margin:0 20px;
  }

  .ant-avatar img{
    cursor:pointer;
  }

  /* TYPOGRAPHY */

  div.ant-typography{
    display: flex;
  }

  .ant-typography-copy, .ant-typography-edit, .ant-typography-expand{
    color:$obviousgrey;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .ant-typography-copy:hover, .ant-typography-edit:hover, .ant-typography-expand:hover{
    color:$black;
  }

  /* SPACE */
  .ant-space{
    width:100%;
  }
  
  /* FORM */

  .ant-form-vertical .ant-form-item-label{
    padding:0;
  }
  
  .ant-form-item-label>label{
    font-weight: 600;
    width: 100%;
    margin:0;
    font-size: 0.8rem;
    word-wrap: break-word;
  }
  
  .ant-form-item-label>label:after{
    content:"";
  }

  .ant-form-item{
    margin:0 0 5px;
  }
  
  .ant-form-item-explain-error{
    color:$red;
  }
  
  .ant-input, .ant-input-affix-wrapper, .ant-input-number{
    border: none;
    border-bottom:1px solid $obviousgrey;
    border-radius: 0px;
  }
  
  
  
  .ant-input:hover, .ant-input-affix-wrapper:hover,.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover, .ant-input-number:hover{
    border: none;
    border-bottom: 1px solid $primary;
  }

  .ant-input-number{
    width:100%;
  }
  
  .ant-checkbox-input:focus+.ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner{
    border-color: $primary;
  }
  
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color:$primary;
    border-color: $primary;
  }
  
  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before,.ant-form-item-explain.ant-form-item-explain-error{
    color:$red;
  }
  
  .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input-affix-wrapper:hover, .ant-form-item-has-error .ant-input:hover{
    border-color:$red;
  }
  
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: $primary;
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: $primary;
  }

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: $white;
    border-color: $primary;
}

.ant-radio-button-wrapper{
    background:transparent;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before{
    background:transparent;
    border:transparent;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background:$primary;
    box-shadow: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
    background:$primarybis;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover{
    color:$primary !important;
}

.ant-radio-button-wrapper, .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within, .ant-radio-button-wrapper:first-child{
    border:none;
    box-shadow: none;
}

.cardOptions .ant-radio-button-wrapper:before{
  background: transparent;
}

.cardOptions .ant-radio-button-checked{
  border-left:5px solid $primary;
}

.ant-picker-now-btn{
  color:$primary;
}

  /* STEPS */
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon, .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after{
    background: $primary;
  }
  
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: $icewhite;
    cursor: default;
    font-weight: 600;
  }
  
  .ant-steps-item-icon{
    width:60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ant-steps-item-tail {
    top: 30px;
    left:60px;
  }
  
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail{
    display: block;
  }
  
  .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon{
    color:$primary;
  }
  
  .ant-steps-item-finish .ant-steps-item-icon{
    border-color:$primary;
  }
  
  /* CARD */
  
  .ant-card{
    box-shadow: 10px 10px 30px rgba(174, 189, 192, 0.25), -10px -10px 30px rgba(255, 255, 255, 0.25);
    margin:10px;
  }

  .ant-card-meta{
    margin-bottom:15px;
  }
  
  .ant-card-meta-title, .ant-card-head-title{
    text-align: center;
    font-size:1.1rem;
    font-weight: 700;
    text-transform: capitalize;
  }

  .ant-card-meta-title{
    margin-bottom:20px;
  }

  .ant-card-meta-description{
    text-align: center;
    color:$primary;
  }
  
  .ant-card-actions>li>span, .ant-card-actions>li>span a:not(.ant-btn), .ant-card-cover{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:20px;
  }

  .ant-card-cover .anticon{
    font-size:4rem;
    color:$primary;
    padding-top:20px;
  }
  
  .form-card {
    padding:30px 0;
  }
  
  .form-card form .ant-form-item{
    margin-right:10%;
  }
  
  .form-card .ant-col-4{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }

  .ant-card.smallCard .ant-card-actions>li{
    margin:5px 0;
  }

  .ant-card.smallCard .ant-card-actions > li > span{
    padding-top:0px;
  }

  .ant-card-actions > li > span{
    padding-top:0px;
  }

  .smallCard .ant-card-meta-title{
    font-size:0.8rem;
  }

  .smallCard .ant-card-body{
    padding:10px;
  }
  
  /* POPOVER */
  
  .ant-popover-message .anticon-question-circle{
    color:$red;
  
  }
  
  /* MODAL */
  .ant-modal-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom:30px;
  }
  
  /* TABS */
  
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color:$primary;
  }
  
  .ant-tabs-tab:hover{
    color:$primarybis;
  }
  
  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar{
    background: $primary;
  }
  
  /* CALENDAR */
  
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    background: $primary;
  }
  
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
    border-color: $primary;
  }
  
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    color:$primary;
  }

  .ant-picker-cell-today {
    background: $grey;
  }

  .ant-picker-cell-selected {
    background: $primary;
  }

  .date-occurence {
    color: $primary;
    font-weight: 700;
  }

  .ant-picker-cell-selected .date-occurence {
    color: $icewhite;
  }

  .ant-picker-cell-selected span {
    color: $icewhite
  }
  
  /* LIST */
  .listcol .ant-spin-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .ant-list{
    width:100%;
  }

  .ant-list-grid .ant-col>.ant-list-item {
    display: flex;
    min-width: 45%;
    background: #fff;
    padding: 20px;
    margin: 10px 0;

    .ant-list-item-action{
      margin: auto;

      li {
        display: flex;
        align-items: center;
      }
    }
  }


  .ant-list-item{
    min-width: 45%;
    background: $icewhite;
    padding:20px;
    margin:10px 0;
    box-shadow: 10px 10px 30px rgba(174, 189, 192, 0.25), -10px -10px 30px rgba(255, 255, 255, 0.25);
  }

  .ant-list-item.itemClick{
    cursor:pointer;
  }
  
  .ant-list-item-meta{
    align-items: center;
    margin-right: 10px;
  }

  .ant-list-item-meta-avatar{
    color:$white;
    background:$primary;
    padding:10px;
    border-radius: 100%;
    display: flex;
  }
  
  .ant-list-item .ant-descriptions{
    max-width: 22%;
  }

  .ant-list-item-meta-description{
    font-size: 0.8rem;
  }
  
  .ant-list-item .ant-descriptions-row>td,.ant-list-item .ant-descriptions-row>th{
    padding-bottom: 0;
  }
  
  h4.ant-list-item-meta-title{
    margin:0;
    font-weight: 800;
  }
  
  .listcol .ant-list-item-meta-avatar{
    background: $primary;
    border-radius: 100%;
    padding:10px;
    color:$icewhite;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ant-list-item-meta-content{
    width: auto;
  }
  
  .ant-list-item-action{
    margin-left: 24px;
    display: flex;
  }

  .ant-list.cardList .ant-list-item{
    background: transparent;
    padding:0;
    box-shadow: none;
    border:none;
  }

  .ant-list.cardList .ant-list-item .ant-list-item-meta-avatar{
    color:$black;
    background: transparent;
  }

  .ant-list.cardList .ant-typography-secondary{
    font-size:0.8rem;
  }
  
  .formList{
    max-width: 60px;
    margin-right: 10px;
  }
  
  .formList .ant-input-number{
    max-width: 100%;
  }

  .ant-list .hightlight,  .ant-list .hightlight .ant-list-item-meta-title{
    color:$white;
    background:$primary;
    cursor:pointer;
  }
  
  .list_selected, .list_selected .ant-list-item-meta-title, .list_selected .ant-list-item-meta-description{
    background: $primary;
    color:$icewhite;
  }

  .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child{
    border:none;
  }
  
  /* pagination */

  .ant-pagination-item{
    border:0px;
}

.ant-pagination-item-active, .ant-pagination-item{
    background: transparent;
}

.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-pagination-item-active{
    color:$primary;
}

.ant-pagination-prev .ant-pagination-item-link:hover, .ant-pagination-next .ant-pagination-item-link:hover,.ant-pagination-prev .ant-pagination-item-link:focus, .ant-pagination-next .ant-pagination-item-link:focus{
    color:$primary;
    border-color:$primary;
}
   
  /* Drag Zone */
  .dragzone{
    border:1px dashed $grey;
    background: $lightgrey;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dragzone .ant-list-item{
    display: flex;
    flex-direction: row;
  }
  
  .dragzone .ant-list-item .ant-list-item-meta, .dragzone .ant-list-item .ant-list-item-meta .ant-list-item-action{
    order:1;
  }
  
  /* dropzone */
  
  #upload-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $lightgrey;
    border:1px dashed $grey;
    min-height: 100px;
    padding:30px;
    margin-bottom:20px;
    margin-right:auto;
    margin-left: auto;
  }
  
  /* TABS */

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
  color:$primary;
  //border-bottom:2px solid $primary;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after{
  border-bottom:2px solid $primary;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 10px;
}

.ant-menu{
  background: transparent;
}

.ant-list .ant-card{
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* BADGE */
.ant-badge{
  display: flex;
}

/* CALENDAR */

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  background: $primary;
  color:$icewhite;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
  border-color: $primary;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active{
  color:$primary;
}

/* EMPTY */

.ant-empty-image .anticon {
  font-size:3rem;
}

.ant-empty{
  margin-top:50px;
}

.ant-btn-primary{
  background: $primary !important;
  border-color: $primary !important;
  text-shadow: none !important;
  display: inline-flex !important;
}

/*
.ant-card{
    box-shadow: 5px 5px 20px rgba(174, 189, 192, 0.25), -5px -5px 20px rgba(255, 255, 255, 0.25);
}

.app_content main.main-content section.ant-layout{
    background: #fff !important;
}

.ant-picker-cell{
    text-align: center;
}

.ant-picker-cell-today{
    border-radius: 50px;
    background-color: $primary;

    .ant-typography{
        color: black !important;
    }
}

.ant-picker-cell-selected{
    border-radius: 50px;
    background-color: $primary;

    #occ{
        color: white !important;
    }

    .ant-typography{
        color: white;
    }
}

.ant-layout-header{
    padding: 0 !important;
}

.ant-typography a{
    color:$black;
}

.ant-typography a:hover{
    color:$primary;
}

.ant-upload.ant-upload-select{
    display: block;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: $primary;
    border-bottom: 2px solid $primary;
}

form .ant-layout-sider-children{
    display: flex;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding: 0;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
}

.ant-list-split .ant-list-item,.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child{
    border-bottom: 0px;
}



.ant-modal-footer{
    display: flex;
}

.ant-card-body{
    display: flex;
}

.item-card{
    cursor: pointer;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
}

.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: 100%;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background-color: $primary;
    border-radius: 2px;
    margin: auto;
}

.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    background: #001529;
    line-height: 30px;
}

.ant-layout-footer {
    padding: 0px 50px;
    padding-top: 15px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background: #f0f2f5;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: $primary;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: $primary;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: $white;
    border-color: $primary;
}

.ant-radio-button-wrapper{
    background:transparent;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before{
    background:transparent;
    border:transparent;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background:$primary;
    box-shadow: none;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover{
    background:$primarybis;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover{
    color:$primary !important;
}

.ant-radio-button-wrapper, .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within, .ant-radio-button-wrapper:first-child{
    border:none;
    box-shadow: none;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: $white;
    background: $primary;
    border-radius: 12px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    position: absolute;
    top: 17px;
    right: 0;
    bottom: 0;
    left: 17px;
    z-index: 1;
    border: 2px solid $primary;
    border-radius: 14px;
    background-color: $primary;
    content: '';
}

.ant-input-affix-wrapper{
    padding: 0;
}

@media screen and (max-width:768px){
    .ant-pagination-item:not(.ant-pagination-item-active){
        display: none;
    }
}
*/