// Variables
@import 'variables';

#login, #register, #passwordReset, #invitation, #confirmation{
    height: 100%;
    display: flex;
    background:#111 url("../img/truck.jpg");
    background-size: cover;
    transition: all 0.5s ease-in;
    min-height: 100vh;
}


#login img, #register img{
    padding:50px;
}

#login .ant-row, #register .ant-row, #passwordReset .ant-row, #invitation .ant-row, #confirmation .ant-row{
    margin-bottom:15px;
}

#login .ant-card-body, #register .ant-card-body, #passwordReset .ant-card-body, #invitation .ant-card-body, #confirmation .ant-card-body{
    padding:30px;
}

#login .ant-card, #register .ant-card, #passwordReset .ant-card, #invitation .ant-card, #confirmation .ant-card{
    box-shadow: none;
}

@media (max-width: 768px) {
    #login, #register, #passwordReset, #invitation, #confirmation{ 
        .ant-col-8{
            flex: auto;
            max-width: 90%;
        }
    }
}