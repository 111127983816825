/* balises init */

body{
    height: auto !important;
    background: $body-bg;
    overflow-x: hidden;
}


ul{
    list-style: none;
    padding:0;
}

img{
    width:100%;
}

/* BUTTON */

button {
    cursor:pointer;
    background:$primary;
    border:none;
    padding:15px 55px;
    color:$white;
    font-weight: 700;
    outline: none;
    transition: all 0.2s ease-in;
}

#editbtn {
    width: 100%;
}

button span,.ant-btn>span{
    font-weight: 600;
}

button:hover span,.ant-btn:hover>span{
    text-decoration: none;
}

/* primary */

button.ant-btn-primary, a.ant-btn-primary{
    background: $primary;
    color:$white;
    border:0px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.ant-btn-primary span, a.ant-btn-primary span{
    vertical-align: middle;
}

button.ant-btn-primary:hover,button.ant-btn-primary:active, button.ant-btn-primary:focus, a.ant-btn-primary:hover, a.ant-btn-primary:active, a.ant-btn-primary:focus{
    background:$primarybis;
    color:$white !important;
}

button.ant-btn-text{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* dashed */

button.ant-btn-dashed:hover, .ant-popover-buttons:hover{
    color:$primary;
    border-color:$primary;
}

a.backbutton {
    width: fit-content;
    display: block;
    text-decoration: none;
}

a.backbutton button{
    margin-bottom:20px;
}

/* error */
span.error{
    color:$red;
    margin-left:auto;
    margin-right:auto;
    display: block;
}

/* Section */

section.orange{
    background:$primary;
    color:$white;
}

section.orange p, section.orange h3{
    color:$white;
}

section.main-container{
    margin-left:80px;
    padding-top:5vh;
}

section.container_orange{
    padding:15vh 12.5vw;
    margin-right:auto;
    margin-left:auto;
    background: $primary;
}

main.app-content{
    padding:2vh 7vw 2vh 7vw;
    background: $body-bg;
}

/* NAVBAR */
aside.main_nav{
    position: fixed;
    top:0;
    bottom:0;
}

aside.main_nav button{
    background:transparent;
    border:none;
    margin:auto;
    height:55px;
    width:80px;
    border-left:3px solid rgb(42, 42, 42);
}

aside.main_nav button.selected{
    background:#3A3A3A;
    border:none;
    margin:auto;
    height:55px;
    width:80px;
    border-left:3px solid $primary;
}

aside.main_nav button:hover{
    transform: scale(1);
    background:#3A3A3A;
    border-left:3px solid #3A3A3A !important;
}

aside.main_nav button.selected:hover{
    border-left:3px solid $primary !important;
}

aside.main_nav button.logout_button{
    padding:3vh 0 10vh 0;
}

aside.main_nav button.profil_button:hover{
    border-left:none !important;
}

/* TOOLBAR */

.toolbar{
    margin-bottom:30px;
}

/* RESPONSIVITE */
@media screen and (min-width:2560px){
    section.main_container{
        max-width: 65vw;
    }
}

@media screen and (max-width:1920px){
    section.main_container{
        max-width: 85vw;
    }
}