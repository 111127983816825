@import 'variables';

/* NAVBAR */

aside.main_nav{
    position: fixed;
    background-color: $black;
    top:0;
    bottom:0;
}

// aside.main_nav .navbar{
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     padding:10vh 0;
//     height: 100vh;
// }

aside.main_nav .navbar .ant-space-item{
    width: 100%;
}

aside.main_nav .ant-menu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border:0;
    overflow: hidden;
}

aside.main_nav .ant-menu::after{
    display: none;
}

aside.main_nav .ant-menu-inline .ant-menu-item{
    padding:0 !important;
}

aside.main_nav li{
    display: flex; 
    justify-content: center;
    align-items: center;
    border:none;
    border-left:3px solid $black !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: $black;
}

.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after{
    border-right: 3px solid $black;
}

aside.main_nav li:hover{
    border:none;
    border-left:3px solid $grey !important;
    background:$lightblack;
}

aside.main_nav li.selected{
    border:none;
    border-left:3px solid $primary !important;
    background: $black linear-gradient(270deg, rgba(33, 33, 33, 0) 0%, rgba(45, 45, 45, 0.39) 100%);
}

aside.main_nav li.selected:hover{
    border-left:3px solid $primary !important;
    background:$lightblack;
}

aside.main_nav li span{
    display: flex;
    justify-content: center;
    align-items: center;
}

aside.main_nav li span.anticon{
    transform: scale(1.4);
    margin:0;
    padding:20px 0;
    color:$icewhite;
}

aside.main_nav li.selected span.anticon{
    color:$primary;
}



// aside.main_nav .profile_menu,aside.main_nav .profile_menu:hover{
//     margin-top:10vh;
//     border:none;
//     border-left:3px solid $black !important;
//     background: none;
// }

aside.main_nav .logout_button,aside.main_nav .logout_button:hover{
    padding:3vh 0 10vh 0;
    border-left:3px solid $black !important;
    cursor:pointer;
    background: none;
}

aside.main_nav .logout_button:hover span.anticon{
    color:$primary !important;
}
@media screen and (min-height:561px){
    
    aside.main_nav .profile_menu,aside.main_nav .profile_menu:hover{
        margin-top:10vh;
        border:none;
        border-left:3px solid $black !important;
        background: none;
    }
    aside.main_nav .navbar{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding:10vh 0;
        height: 100vh;
    }
}
@media screen and (max-height:560px){
    
    aside.main_nav .profile_menu,aside.main_nav .profile_menu:hover{
        margin-top:0vh;
        border:none;
        border-left:3px solid $black !important;
        background: none;
    }
    aside.main_nav .navbar{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding:0vh 0;
        height: 100vh;
    }
}